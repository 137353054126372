import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const useAuthentication = () => {
  const store = useStore();
  const router = useRouter();

  const validateAuthentication = async (name = 'recipes') => {
    const { status } = await store.dispatch('auth/checkAuthentication');
    if (status) router.push({ name });
    else router.push({ name: 'login' });
  };

  return {
    validateAuthentication,
  };
};

export default useAuthentication;
