<template>
  <Title title="Ingredientes" />
  <ContentMain>
    <template v-slot:list>
      <Search />
      <div class="content-grid-list-items scroll">
        <Item />
        <!-- <Skeleton skeleton="item" /> -->
      </div>
    </template>
    <template v-slot:form>
      <!-- <FormSkeleton /> -->
      <FormMainIngredient />
      <ParnetProduct />
      <Equivalences />
      <Characteristics />
      <Categories />
    </template>
  </ContentMain>
  <Fab text="Ingrediente" />
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { onBeforeMount } from 'vue';
import useAuthentication from '@/modules/auth/composables/useAuthentication';

export default {
  components: {
    Title: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Title" */ '@/modules/cms/components/Title.vue'
      )
    ),
    ContentMain: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "ContentMain" */ '@/modules/cms/components/ContentMain.vue'
      )
    ),
    Search: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Search" */ '@/modules/cms/components/Search.vue'
      )
    ),
    Item: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Item" */ '@/modules/cms/components/Item.vue')
    ),
    Fab: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Fab" */ '@/modules/cms/components/Fab.vue')
    ),
    Skeleton: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Skeleton" */ '@/modules/cms/components/skeletons/Skeleton.vue'
      )
    ),

    FormSkeleton: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "FormSkeleton" */ '@/modules/cms/parts/ingredients/FormSkeleton.vue'
      )
    ),
    FormMainIngredient: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "FormMainIngredient" */ '@/modules/cms/parts/ingredients/FormMainIngredient.vue'
      )
    ),
    ParnetProduct: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "ParnetProduct" */ '@/modules/cms/parts/ingredients/parnetProduct/ParnetProduct.vue'
      )
    ),
    Equivalences: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Equivalences" */ '@/modules/cms/parts/ingredients/equivalences/Equivalences.vue'
      )
    ),
    Characteristics: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Categories" */ '@/modules/cms/parts/ingredients/characteristics/Characteristics.vue'
      )
    ),
    Categories: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Categories" */ '@/modules/cms/parts/ingredients/categories/Categories.vue'
      )
    ),
  },

  setup() {
    onBeforeMount(() => {
      const { validateAuthentication } = useAuthentication();
      validateAuthentication('ingredients');
    });
  },
};
</script>
